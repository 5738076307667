import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect, useState} from "react";
import {unsetDossier} from "../../app/rootReducer";
import {useParams} from "react-router-dom";
import {dossierTypeEnum} from "../../types/dossierTypeEnum";
import {Helmet} from "react-helmet";

const Result = () => {

    const [dossier, setDossier] = useState<any>();
    const [vehicleOwner, setVehicleOwner] = useState('');
    const {helpType, root} = useAppSelector(state => state);

    const dispatch = useAppDispatch()
    let {slug} = useParams();

    const localDossierUuidKey = slug + '-dossier_uuid';
    const localDossierAccessTokenKey = slug + '-dossier_access_token';
    const {
        activeHelpType,
    } = helpType;

    useEffect(() => {
            setDossier(root.dossier)
            localStorage.removeItem(localDossierUuidKey)
            localStorage.removeItem(localDossierAccessTokenKey)
            dispatch(unsetDossier())
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

            if (activeHelpType === dossierTypeEnum.GlassReplacement || activeHelpType === dossierTypeEnum.GlassRepair) {
                window.dataLayer.push({
                    event: "RuitschadeAanmeldenBedankt",
                })
            }

            if (activeHelpType === dossierTypeEnum.BodyRepair) {
                window.dataLayer.push({
                    event: "AutoschadeAanmeldenBedankt",
                });
            }

            if (activeHelpType === dossierTypeEnum.CaravanRepair) {
                window.dataLayer.push({
                    event: "CamperschadeAanmeldenBedankt",
                });
            }

        }, []
    );


    useEffect(() => {

        if (dossier && dossier.lease_car === 1 && dossier.lease_type?.code === 'private') {
            setVehicleOwner('privateLease')
            return
        }

        if (dossier && dossier.lease_car === 1 && dossier.lease_type?.code === 'business') {
            setVehicleOwner('zakelijkeLease')
            return
        }

        if (dossier && dossier.lease_car === 0) {
            setVehicleOwner('verzekeraar')
            return
        }

    }, [dossier])


    return (

        <>
            <Helmet>
                {!!vehicleOwner && vehicleOwner.length > 0 &&
                    <script type="text/javascript">
                        {`
                    (function () {
                    var a = document.createElement('script');
                    a.src = 'https://web-f.insocial.nl/survey-loader-2.1.6.min.js';
                    a.integrity = 'sha384-bOr7CJSIdO9uLEp5a1feYK5rPQYrVRXZFcpUybp6BMe36EU3PlAvpLYzLQlcdGq+';
                    a.async = 'true';
                    a.crossOrigin = 'anonymous';
                    a.addEventListener('load', function() {
                    surveyLoader.init({
                    surveyId:"f84546ae-4828-11ef-b936-0694279ff40f",
                    trigger:["onSite"],
                    tracking:false,
                    secondsOnSite:1,
                    triggerConfig:{
                    visible:false
                },
                    metadata:{
                    event:"completed",
                    vehicleOwner:"` + vehicleOwner + `" //private lease, zakelijke lease, verzekeraar |
                },
                    session:{
                    type:"none" //hard = 28d, soft = browser session
                }
                });
                });
                    document.head.appendChild(a);
                })
                    ();
                `}</script>
                }
            </Helmet>


            <div className="s-confirmed">
                <div className="o-container o-container--secondary">
                    <div className="s-confirmed__content">
                        {activeHelpType === dossierTypeEnum.CaravanRepair ? (
                            <>
                                <h1>Gelukt! 👍</h1>
                                <h2>Jouw schademelding is door ons ontvangen!</h2>
                                <p>Het dossier wordt nu naar de gekozen vestiging verzonden. Zij zullen z.s.m. contact
                                    opnemen om de melding samen door te nemen.</p>
                            </>
                        ) : (
                            <>
                                <h1>Bedankt, de schademelding is bijna compleet!</h1>
                                <h2>Wat gebeurt er nu?</h2>
                                <ul className="u-reset-ul">
                                    <li>Je ontvangt een fotolink per sms.</li>
                                    <li>Zodra je die hebt afgerond delen wij jouw gegevens met de vestiging van jouw
                                        keuze
                                        en krijg je een bevestiging daarvan in je mail.
                                    </li>
                                    <li>De vestiging neemt contact met je op om de afspraak te bevestigen of om een
                                        andere
                                        datum met jou te plannen.
                                    </li>
                                </ul>
                            </>
                        )}
                    </div>
                    <div className="s-confirmed__image">
                        <img src="/images/confirmed-guy.png" alt="ABS auto spuiter"/>
                        <img className="s-confirmed__wave" src="/images/wave-pattern.svg" alt="ABS Boog patroon"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Result
