import {useState, useEffect} from "react";
import Select from "react-select/async";
import debounce from "debounce-promise";
import Fuse, {IFuseOptions} from "fuse.js";

interface Option {
    label: string,
    value: number | string
}

interface Props {
    options: Option[],
    fuzzyOptions: IFuseOptions<Option>,
    wait: number,
    value: number | string,
    onChange: (value: Option | null) => void
}

const FuzzyReactSelect = (props: Props) => {
    const [fuse, setFuse] = useState<any>(null);

    const activeOption = props.options.find((option: Option) => {
        return option.value === props.value
    }) as Option

    const searchOptions = (inputValue: string) =>
        new Promise(resolve => {
            resolve(fuse.search(inputValue).map((c: any) => ({...c.item})));
        });

    const loadOptions = (inputValue: string) => searchOptions(inputValue);

    const debouncedLoadOptions = debounce(loadOptions, props.wait);

    useEffect(() => {
        setFuse(new Fuse(props.options, props.fuzzyOptions));
        return () => setFuse(null);
    }, [props.options, props.fuzzyOptions]);

    useEffect(() => {
        if ((props.options, fuse)) {
            fuse.setCollection(props.options);
        }
    }, [fuse, props.options]);

    const styles = {
        control: (base: any) => ({
            ...base,
            fontSize: '1.5rem',
            borderColor: '#D1D1D1'
        }),
        menu: (base: any) => ({
            ...base,
            fontSize: '1.5rem'
        })
    };

    return (
        <Select
            value={activeOption}
            styles={styles}
            maxMenuHeight={190}
            isMulti={false}
            menuPlacement={'top'}
            className={'font-face-primary'}
            defaultOptions={props.options}
            onChange={props.onChange}
            // @ts-ignore
            loadOptions={(value: string) => debouncedLoadOptions(value)}
        />
    );
};

export default FuzzyReactSelect;